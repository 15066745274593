import { Theme, TypeText } from '@mui/material/styles';

interface TypeTextExtended extends TypeText {
  primaryAlt2: string;
}

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  const primaryAlt2 = (theme.palette.text as TypeTextExtended)
    .primaryAlt2;
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.primary }
          }
        },
        multiline: {
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1)
        },
        input: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.primary },
            color: `${theme.palette.text.primary} !important`,
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
            opacity: 1
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: `${primaryAlt2} !important`
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16]
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          },
          borderBottomRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1)
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.info.dark
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.info.dark
            }
          }
        }
      }
    }
  };
}
