import { alpha } from 'src/theme/palette';
import {
  CircleAlert,
  CircleCheck,
  Info,
  MessageSquareWarning,
  X
} from 'lucide-react';
import { SnackbarProvider } from 'notistack';
import React, { ReactNode, createRef } from 'react';
import { ColorSchema } from 'src/@types/theme';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import { SnackbarUtilsConfigurator } from 'src/utils/snackbar';
import IconButton from './IconButton';

// ----------------------------------------------------------------------

function SnackbarIcon({
  icon,
  color
}: {
  icon: React.ReactElement;
  color: ColorSchema;
}) {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  return (
    <span
      className="mr-3 flex h-10 w-10 items-center justify-center"
      style={{
        borderRadius: 1.5,
        color: `${color}.main`,
        backgroundColor: alpha(palette[color].main, 0.32)
      }}
    >
      {icon}
    </span>
  );
}

function NotistackProvider({ children }: { children: ReactNode }) {
  const notistackRef: React.Ref<SnackbarProvider> = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);

  return (
    <SnackbarProvider
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      iconVariant={{
        success: (
          <SnackbarIcon icon={<CircleCheck />} color="success" />
        ),
        error: <SnackbarIcon icon={<CircleAlert />} color="error" />,
        warning: (
          <SnackbarIcon
            icon={<MessageSquareWarning />}
            color="warning"
          />
        ),
        info: <SnackbarIcon icon={<Info />} color="info" />
      }}
      classes={{}}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          onClick={onClickDismiss(key)}
          style={{
            ['--icon-button-bg-color-hover' as string]: alpha(
              palette.text.primary,
              0.16
            )
          }}
        >
          <X />
        </IconButton>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
