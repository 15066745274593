// MUI @mui/system v5.15.6 \\node_modules\@mui\system\colorManipulator.js
/**
 * Sets transparency of a color.
 * @param {string} color - CSS color: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param {number} value - alpha channel in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function alpha(color, value) {
  color = decomposeColor(color);
  value = clampWrapper(value);
  if (color.type === 'rgb' || color.type === 'hsl') {
    color.type += 'a';
  }
  color.values[3] = color.type === 'color' ? `/${value}` : value;
  return recomposeColor(color);
}
/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 * @param {string} color - CSS color: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @returns {object} - A MUI color object: {type: string, values: number[]}
 */
function decomposeColor(color) {
  // Idempotent
  if (color.type) {
    return color;
  }
  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }
  const marker = color.indexOf('(');
  const type = color.substring(0, marker);
  if (['rgb', 'rgba', 'hsl', 'hsla', 'color'].indexOf(type) === -1) {
    throw new Error(
      process.env.NODE_ENV !== 'production' &&
        `MUI: Unsupported \`${color}\` color.
The following formats are supported: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color().`
    );
  }
  let values = color.substring(marker + 1, color.length - 1);
  let colorSpace;
  if (type === 'color') {
    values = values.split(' ');
    colorSpace = values.shift();
    if (values.length === 4 && values[3].charAt(0) === '/') {
      values[3] = values[3].slice(1);
    }
    if (
      [
        'srgb',
        'display-p3',
        'a98-rgb',
        'prophoto-rgb',
        'rec-2020'
      ].indexOf(colorSpace) === -1
    ) {
      throw new Error(
        process.env.NODE_ENV !== 'production' &&
          `MUI: unsupported \`${colorSpace}\` color space.
The following color spaces are supported: srgb, display-p3, a98-rgb, prophoto-rgb, rec-2020.`
      );
    }
  } else {
    values = values.split(',');
  }
  values = values.map((value) => parseFloat(value));
  return {
    type,
    values,
    colorSpace
  };
}
function recomposeColor(color) {
  const { type, colorSpace } = color;
  let { values } = color;
  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n, i) => (i < 3 ? parseInt(n, 10) : n));
  } else if (type.indexOf('hsl') !== -1) {
    values[1] = `${values[1]}%`;
    values[2] = `${values[2]}%`;
  }
  if (type.indexOf('color') !== -1) {
    values = `${colorSpace} ${values.join(' ')}`;
  } else {
    values = `${values.join(', ')}`;
  }
  return `${type}(${values})`;
}
/**
 * Returns a number whose value is limited to the given range.
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
function clampWrapper(value, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      // eslint-disable-next-line
      console.error(
        `MUI: The value provided ${value} is out of range [${min}, ${max}].`
      );
    }
  }
  function clamp(
    val,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER
  ) {
    return Math.max(min, Math.min(val, max));
  }
  return clamp(value, min, max);
}
/**
 * Converts a color from CSS hex format to CSS rgb format.
 * @param {string} color - Hex color: #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
function hexToRgb(color) {
  color = color.slice(1);
  let colors = color.match(
    new RegExp(`.{1,${color.length >= 6 ? 2 : 1}}`, 'g')
  );
  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }
  return colors
    ? `rgb${colors.length === 4 ? 'a' : ''}(${colors
        .map((n, index) => {
          return index < 3
            ? parseInt(n, 16)
            : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
        })
        .join(', ')})`
    : '';
}

export function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}
export function createAngleGradient(
  angle: string,
  color1: string,
  color2: string
) {
  return `linear-gradient(${angle}, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info?: string;
  success?: string;
  warning?: string;
  error?: string;
}

// declare module '@mui/material/styles/createPalette' {
declare module '@mui/material/styles' {
  interface TypeBackground {
    neutral: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chip: Palette['primary'];
    border: {
      hover: string;
      main: string;
      color: string;
    };
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chip?: PaletteOptions['primary'];
  }
}

// declare module '@mui/material/TextField' {
//   interface TextFieldPropsColorOverrides {
//     input: true;
//   }
// }

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// Main colors (light mode and dark mode)
const PRIMARY = {
  darkMode: {
    light: '#098D67',
    main: '#4fd8a6',
    dark: '#098D67'
  },
  lightMode: {
    light: '#439ecc',
    main: '#2463ea',
    dark: '#307293'
  }
};
const SECONDARY = {
  darkMode: {
    light: '#06BEB8',
    main: '#7f48df',
    dark: '#3B3BCC'
  },
  lightMode: {
    light: '#109aef',
    main: '#205374',
    dark: '#194d6d'
  }
};
const INFO = {
  darkMode: {
    light: '#a1a1a1',
    main: '#919090',
    dark: '#343436'
  },
  lightMode: {
    light: '#2d2d2d',
    main: '#868686',
    dark: '#828282'
  }
};
const WARNING = {
  darkMode: {
    light: '#fdc173',
    main: '#ebbd46',
    dark: '#db6e1b'
  },
  lightMode: {
    light: '#fdc173',
    main: '#db6e1b',
    dark: '#db6e1b'
  }
};
const ERROR = {
  darkMode: {
    light: '#fb8c81',
    main: '#FF2124',
    dark: '#B50006'
  },
  lightMode: {
    light: '#fb8c81',
    main: '#FF2124',
    dark: '#B50006'
  }
};

const GREY = {
  0: '#666666',
  100: '#5C5C5C',
  200: '#525252',
  300: '#474747',
  400: '#3D3D3D',
  500: '#333333',
  600: '#292929',
  700: '#1F1F1F',
  800: '#141414',
  900: '#0A0A0A',
  500_8: alpha('#474747', 0.08),
  500_12: alpha('#474747', 0.12),
  500_16: alpha('#474747', 0.16),
  500_24: alpha('#474747', 0.24),
  500_32: alpha('#474747', 0.32),
  500_48: alpha('#474747', 0.48),
  500_56: alpha('#474747', 0.56),
  500_80: alpha('#474747', 0.8)
};

//TODO: Create gradients for light mode
const GRADIENTS = {
  primary: createAngleGradient(
    '90deg',
    SECONDARY.darkMode.main,
    WARNING.darkMode.main
  ),
  secondary: createAngleGradient(
    '33deg',
    PRIMARY.darkMode.main,
    PRIMARY.darkMode.light
  ),
  tertiary: createAngleGradient('33deg', '#ffffff', '#ffba58'),
  page: createAngleGradient(
    '33deg',
    PRIMARY.darkMode.main,
    SECONDARY.darkMode.main
  )
};

const COMMON = {
  common: { black: '#000000', white: '#ffffff' },
  textColor: { light: '#cdcfd8', dark: '#272728' },
  input: {
    main: GREY[500],
    contrastText: '#ffffff'
  },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_56],
  action: {
    hover: GREY[500_16],
    selected: GREY[500_32],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_48],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  },
  chip: {
    main: '#F6F7F7',
    contrastText: '#090808'
  },
  sdgs: {
    1: '#E5243B',
    2: '#DDA63A',
    3: '#4C9F38',
    4: '#C5192D',
    5: '#FF3A21',
    6: '#26BDE2',
    7: '#FCC30B',
    8: '#A21942',
    9: '#FD6925',
    10: '#DD1367',
    11: '#FD9D24',
    12: '#BF8B2E',
    13: '#3F7E44',
    14: '#0A97D9',
    15: '#56C02B',
    16: '#00689D',
    17: '#19486A'
  }
};

const palette = {
  light: {
    ...COMMON,
    primary: { ...PRIMARY.lightMode },
    secondary: { ...SECONDARY.lightMode },
    info: { ...INFO.lightMode },
    warning: { ...WARNING.lightMode },
    success: { ...PRIMARY.lightMode },
    error: { ...ERROR.lightMode },
    contrast: COMMON.common.black,
    text: {
      primary: '#000000',
      primaryAlt1: '#000000',
      primaryAlt2: '#000000',
      secondary: COMMON.textColor.light
    },
    background: {
      default: '#f9fafc',
      paper: '#f9fafc',
      neutral: '#eff7ff',
      workstation: '#cecee2',
      boxShadow: '#39393933',
      skeleton: '#e2e2e276'
    },
    border: {
      hover: '#343436',
      main: '#c7c7c9',
      color: COMMON.common.black
    },
    button: { disabled: '#919090' },
    link: { color: PRIMARY.lightMode.dark },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    primary: { ...PRIMARY.darkMode },
    secondary: { ...SECONDARY.darkMode },
    info: { ...INFO.darkMode },
    warning: { ...WARNING.darkMode },
    success: { ...PRIMARY.darkMode },
    error: { ...ERROR.darkMode },
    contrast: COMMON.common.white,
    text: {
      primary: COMMON.textColor.light,
      primaryAlt1: '#999ba6',
      primaryAlt2: '#b0b0b2',
      secondary: COMMON.textColor.dark
    },
    background: {
      paper: '#002628',
      default: '#001e21',
      neutral: '#001011',
      skeleton: '#2c2c2d',
      boxShadow: '#00000057',
      workstation: '#0c0c0e'
    },
    border: {
      color: COMMON.common.white,
      main: '#374142',
      hover: '#9f9f9f'
    },
    button: { disabled: '#a0a0a0' },
    link: { color: PRIMARY.darkMode.light },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export function getPalette({
  themeMode
}: {
  themeMode: 'light' | 'dark';
}) {
  const themedPalette = palette[themeMode];
  if (themeMode === 'light') {
    themedPalette['navigation'] = {
      topbar: themedPalette.background.default,
      sidebar: themedPalette.background.default,
      subnav: themedPalette.background.neutral,
      panel: themedPalette.background.paper
    };
  } else {
    themedPalette['navigation'] = {
      topbar: themedPalette.background.default,
      sidebar: themedPalette.background.default,
      subnav: themedPalette.background.paper,
      panel: themedPalette.background.paper
    };
  }

  return palette[themeMode];
}

export default palette;
