import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SettingsStoreType = {
  themeMode: 'dark' | 'light';
  toggleThemeMode: () => void;
  sideNavOpenState: {
    [key in 'organization' | 'inventory' | 'settings']: boolean;
  };
  activeReportingPeriodId: string;
  activeReportingPeriodStartDate: Date | null;
  activeReportingPeriodEndDate: Date | null;
  setSideNavOpenState: (newState: {
    [key in 'organization' | 'inventory' | 'settings']: boolean;
  }) => void;
  setActiveReportingPeriodId: (newState: string) => void;
  setActiveReportingPeriodStartDate: (newState: Date) => void;
  setActiveReportingPeriodEndDate: (newState: Date) => void;
};

const clearState = () => useSettingsStore.persist.clearStorage();
export const useSettingsStore = create(
  persist<SettingsStoreType>(
    (set) => ({
      clear: () => {
        clearState();
        set(() => ({
          themeMode: 'light',
          toggleThemeMode: () =>
            set((state) => ({
              themeMode: state.themeMode === 'dark' ? 'light' : 'dark'
            })),
          sideNavOpenState: {
            organization: false,
            inventory: false,
            settings: false
          },
          activeReportingPeriodId: '',
          activeReportingPeriodStartDate: null,
          activeReportingPeriodEndDate: null
        }));
      },
      themeMode: 'light',
      toggleThemeMode: () =>
        set((state) => ({
          themeMode: state.themeMode === 'dark' ? 'light' : 'dark'
        })),
      sideNavOpenState: {
        organization: false,
        inventory: false,
        settings: false
      },
      activeReportingPeriodId: '',
      activeReportingPeriodStartDate: null,
      activeReportingPeriodEndDate: null,
      setSideNavOpenState: (newState) =>
        set({ sideNavOpenState: newState }),
      setActiveReportingPeriodId: (newState) =>
        set({ activeReportingPeriodId: newState }),
      setActiveReportingPeriodStartDate: (newState) =>
        set({ activeReportingPeriodStartDate: newState }),
      setActiveReportingPeriodEndDate: (newState) =>
        set({ activeReportingPeriodEndDate: newState })
    }),
    {
      name: 'settings-store'
    }
  )
);
